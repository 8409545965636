/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */


@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-label {
    color: rgba(var(--fuse-text-default-rgb), var(--tw-text-opacity)) !important;
    font-weight: 500;
}

.no-label {
    margin-top: 0 !important;
}

.no-label.mat-form-field.mat-form-field-appearance-fill.mat-form-field-has-label .mat-form-field-wrapper {
    margin-top: 0 !important;
}

.no-label.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper:not(.mdc-text-field--no-label) {
    margin-top: 0 !important;
}

.image-input-border {
    padding: 0;
    border-radius: 6px;
    border-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1 !important;
    border-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;

    img {
        border-radius: 6px;
    }
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
    padding: 0 !important;
    background-color: transparent !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    padding: 0 !important;
    background-color: transparent !important;
}

input.mat-mdc-chip-input {
    flex: 1 0 50px !important;
}

.mat-mdc-form-field.mat-form-field-appearance-fill.mat-quill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
    padding: 0 !important;
}

.ql-container.ql-snow {
    border: none !important;
}

.ql-toolbar.ql-snow {
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
}

.ql-container .ql-editor {
    height: fit-content !important;
    max-height: fit-content !important;
}

.rich-text-view-border {
    width: 100% !important;
    padding: 2px !important;
}

.mat-mdc-form-field.mat-form-field-appearance-fill.mat-toggle .mat-mdc-text-field-wrapper {
    background-color: transparent !important;
    border: none !important;
    --tw-shadow: none !important;
    overflow: visible !important;
}

.mat-mdc-form-field.mat-form-field-appearance-fill.mat-toggle .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
    width: auto !important;
}

.mat-slide-toggle-content {
    padding: 0 !important;
}

.mat-mdc-form-field.mat-form-field-appearance-fill.mat-checkbox .mat-mdc-text-field-wrapper {
    background-color: transparent !important;
    border: none !important;
    --tw-shadow: none !important;
    overflow: visible !important;
}

.leading-unset {
    line-height: unset !important;
}

::ng-deep .mat-slide-toggle.mat-disabled {
    opacity: 1 !important;
}

.profile-image {
    width: 30px !important;
    height: auto !important;
    border-radius: 15px;
}

.mat-mdc-checkbox label {
    cursor: pointer;
    font-weight: 600 !important;
    font-size: 0.8125rem;
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-text-secondary-rgb), var(--tw-text-opacity)) !important;
}

.full-screen-image-viewer .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0;
    border-radius: 4px !important;
}

.align-items-self-end {
    align-items: self-end !important;
}

.customer-details .mat-expansion-panel {
    box-shadow: unset !important;
}

.customer-details .mat-expansion-panel-body {
    padding: 0 !important;
}

.customer-details .mat-expansion-panel-header {
    padding: 8px 12px 8px 8px;
    height: unset !important;
}

.mdc-list-item__primary-text {
    width: 100% !important;
}

.no-spacing .mat-mdc-menu-content {
    padding: 0 !important;
}

.notification-alert .mdc-snackbar__surface {
    border-radius: 0.75rem /* 12px */
    !important;
}

.mat-mdc-form-field.inline-mode .mat-mdc-text-field-wrapper {
    width: calc(100% - 1px);
}
